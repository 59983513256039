<template>
    <!-- Loading -->
    <div ref="loading-div" id="loading-div">
        <h5 class="font-weight-bold">Aguarde...</h5>
        <svg style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="100px" height="300px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <circle cx="50" cy="50" fill="none" stroke="var(--cor-primaria)" stroke-width="2" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="2s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
            </circle>
        </svg>
    </div>
</template>

<script>
export default{
    data() {
        return {
            primary: "#0e0e0e",
            secondary: "var(--cor-primaria)",
        }
    }
}
</script>
