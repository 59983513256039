import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
export const router = new VueRouter({
  mode: 'hash',
  path: '/:component?/:id?',
  component: () => import('@/views/BaseView.vue')
})

router.afterEach(to => {
  Vue.prototype.$secureLS.set('ls_route_url', { path: to.fullPath })
})

export default router