<template>
    <div class="mt-5" id="footer">
        <div class="container py-3">
            <div class="d-flex justify-content-between">
                <div>
                    <a href="https://fivedigital.com.br">
                        <img id="logo-five" class="img-responsive" :src="logo" alt="Logo" style="width: 135px; height: 75px;">
                    </a>
                </div>
                <div style="margin-top: 15px;">
                    <a href="https://fivedigital.com.br">
                        <img id="logo-five" class="img-responsive" src="/img/five.png" alt="Logo Five Digital" style="width: 100px; height: 50px;">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            logo: process.env.VUE_APP_LOGO,
        }
    },
}
</script>