<template>
            
    <div class="row no-gutters d-flex justify-content-around">
        <div class="col-12 col-lg-4 d-none d-md-flex align-items-top justify-content-center mt-5">
            <h2 class="text-white font-weight-bold mt-5">
                Proteja seu veículo <br> com a tranquilidade que você merece. <br><br>
                Solicite uma cotação hoje mesmo e garanta a segurança do seu patrimônio.
            </h2>
            <!-- <img src="@/assets/svg/find-plan.svg" alt="" width="250px" height="250px"> -->
        </div>
        <div class="col-12 col-lg-4 d-md-none align-items-center justify-content-center mt-5">
            <h3 class="text-white font-weight-bold px-3">
                Legal, vamos começar sua cotação.
            </h3>
        </div>
        
        <div class="col-12 col-lg-5 mb-5 p-3">
            <div class="box-container mt-3">

                <!-- Stepper -->
                <StepperComp />
                <!-- / Stepper -->

                <div class="row no-gutters">
                    <div class="col-12 col-lg-10">
                        <div class="input-container">
                            <input id="nome_completo" v-model="cliente.nome_completo" class="input" type="text" placeholder=" " @input="cliente.nome_completo = removeCaracteresEspeciais(cliente.nome_completo)"/>
                            <label for="nome_completo" name="nome_completo" class="placeholder">
                                Nome completo <span class="text-danger">*</span>
                            </label>
                        </div>
                        <div class="input-container">
                            <input id="email" v-model="cliente.email" class="input" type="email" placeholder=" " autocomplete="on" />
                            <label for="email" name="email" class="placeholder">E-mail <span class="text-danger">*</span></label>
                        </div>
                        <div class="input-container">
                            <input id="telefone_principal" v-model="cliente.telefone_principal" class="input phone_with_ddd" type="tel" placeholder=" "
                                v-mask="'55 (##) # #### ####'" />
                            <label for="telefone_principal" name="telefone_principal" class="placeholder">Telefone principal <span class="text-danger">*</span></label>
                        </div>
                    </div>

                    <div class="detalhes-form text-left mt-3">
                        <p>Campos destacados com <span style="color: red; font-size: 15px;"> * </span> são obrigatórios.</p>

                        <div>
                            <p>
                                Ao preencher e enviar este formulário, você autoriza (Empresa) a
                                entrar em contato por meio de SMS, ligações, e-mail ou whatsapp.
                            </p>
                        </div>

                        <div>
                            <ul class="input-radio">
                                <li>
                                    <label class="label-termos"> 
                                        <label for="termo" name="termo"></label>
                                        <input id="termo" name="termo" v-model="termo" type="checkbox" :value="termo">
                                        &nbsp;Li e concordo com os
                                        <a :href="linkTermo" target="_blank">Termos de aceite</a> e 
                                        <a :href="linkPolitica" target="_blank">Política de privacidade</a>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="salvaBuscaCliente()" class="next right">
                    Próximo <i class="fa-solid fa-arrow-right-long"></i>
                </button>
            </div>
        </div>
    </div>
        
</template>

<script>

export default {
    components: {
	},

    computed: {
        dadosCliente() {
            return {
                "nome": this.removeCaracteresEspeciais(this.cliente.nome_completo),
                "cpf_cnpj": this.limpaString(this.cliente.cpf),
                "email": this.cliente.email,
                'origem_cadastro': 'pagina_online_vendas',
                "telefone": this.limpaString(this.cliente.telefone_principal),
                "origem": "vendas-online-checkout"
            }
        },
    },

    data() {
        return {
            cliente: {
                id: '',
                nome_completo: '',
                email: '',
                telefone_principal: '',
            },

            termo: false,
            linkTermo: process.env.VUE_APP_LINK_TERMO_ACEITE,
            linkPolitica: process.env.VUE_APP_LINK_POLITICA_PRIVACIDADE,

        }
    },

    methods: {
        // ETAPA 1 - cria ou busca cliente
        async salvaBuscaCliente() {       

            if(this.termo == this.DefaultEnum.NAO){
                this.$swal.fire('Opss!', 'Você precisa aceitar os termos para prosseguir.', 'warning');
                return
            }
            this.clicked();

            await this.$http.post('/prospeccao/v1/prospeccao/vendas-online-checkout', this.dadosCliente)
                .then((response) => {
                    this.cliente = response.data.cliente;
                    this.$secureLS.set('ls_customer', response.data.cliente);

                    // Next
                    this.iniLoading();
                    this.setCurrentComp('veiculo');
                    this.$router.push({ path: `/veiculo/${response.data.cliente.id}`});

                }).catch((err) => {
                    this.isClicked = false;
                    this.validarRetorno('', err.response.data);
                })
        },
    },

    created() {
        // this.setCurrentComp('cotacao')
    },

}
</script>

<style scoped>
.detalhes-form {
    font-size: 11px;
}

input[type=checkbox] {
    height: 11px;
}

.label-termos {
    font-size: 13px;
}
.faq-section {
    height: 70vh;
    width: 100%;
}

</style>