import Vue from 'vue'
import Vuex from 'vuex'
import SecureLS from 'secure-ls'

Vue.use(Vuex)

const secureLS = new SecureLS({
  encodingType: 'aes',
  encryptionSecret: process.env.VUE_APP_ENCRYPTION_LS_KEY,
  isCompression: false
});

const lsCurrentComp = secureLS.get('ls_current_comp') || {};

export default new Vuex.Store({
  state: {
    step: {
      current: lsCurrentComp.comp,
    },
    loading: false,
  },
  mutations: {
    UPDATE_STEP(state, comp) {
      state.step.current = comp;
    },
    UPDATE_LOADING(state, status) {
      state.loading = status;
    }
  },
  actions: {
    updateComponent({ commit }, { comp }) {
      commit('UPDATE_STEP', comp);
      
      secureLS.set('ls_current_comp', { comp });
    }
  },
  getters: {
    getStep: (state) => state.step,
    getLoading: (state) => state.loading
  }
});
