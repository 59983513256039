<template>
    <div>
        <loader ref="loading" :object="secondary" :color1="primary" :color2="secondary" size="2" speed="2" bg="#ffffff"
            objectbg="#008744" opacity="80" disableScrolling="false" name="circular">
        </loader>
    </div>
</template>

<script>
export default{
    data() {
        return {
            primary: "#0e0e0e",
            secondary: "var(--cor-primaria)",
        }
    }
}
</script>
