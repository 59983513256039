<template>

    <div class="row">
        <div class="col-12">
            <!-- Stepper -->
            <StepperComp />
            <!-- / Stepper -->

            <!--  -->
            <div class="row">
                <div class="col-12">
                    <h5 class="text-dark font-weight-bold">
                        Informe os dados do seu veículo
                    </h5>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="form__radio mt-3 mb-3" @click="tipo = DefaultEnum.MANUAL">
                        <label for="dados-do-veiculo">
                            <i class="fa-solid fa-car-side" style="color: #919191;"></i>Selecionar veículo
                        </label>
                        <input name="tipo" class="accent-style" v-model="tipo" value="manual" type="radio" />
                    </div>
                </div>

                <div class="col-sm-12 col-md-6">
                    <div class="form__radio mt-3 mb-4" @click="tipo = DefaultEnum.PLACA">
                        <label for="dados-do-veiculo">
                            <i class="fa-solid fa-id-card" style="color: #919191;"></i>Buscar pela placa
                        </label>
                        <input name="tipo" class="accent-style" :disabled="placa_aviso != null" v-model="tipo" value="placa" type="radio" />
                    </div>
                </div>
            </div>
            <!--  -->


            <!-- PLACA -->
            <div class="row" v-if="tipo == DefaultEnum.PLACA">
                <div class="col-12 mb-3">
                    <p>Digite a placa do veículo para consulta automática</p>
                </div>
                <div class="col-12">
                    <img v-show="pesquisando_placa" class="img-responsive h-auto" src="assets/images/loading.gif" height="35px" style="width: 60px">
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                    <div class="input-container">
                        <input v-mask="['AAA-#X##']" :disabled="pesquisando_placa" :readonly="pesquisando_placa"
                            class="input" v-model="placa" name="placa" type="text" />
                        <label for="placa" class="label-input placeholder">Digite sua placa <span>*</span></label>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6">
                    <div class="input-container">
                        <select v-model="proposta.veiculo_uso" name="veiculo-uso" class="input">
                            <option disabled selected value="">Selecione o uso veículo</option>
                            <option v-for="(uso, p) in veiculos_uso" :key="p" :value="uso">{{ uso.titulo }}</option>
                        </select>
                        <label for="veiculo-uso" class="label-input placeholder">Uso do veículo <span>*</span></label>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 mt-3">
                    <div class="input-container">
                        <select 
                        v-model="proposta.cor" 
                        name="veiculo-modelo" 
                        class="input"
                        :disabled="!veiculo_cores.length">
                            <option disabled value="">
                                {{ !veiculo_cores.length ? "Carregando cores..." : "Selecione a cor do veículo" }}
                            </option>
                            <option v-for="(cor, idx) in veiculo_cores" :key="idx" :value="cor">
                                {{ cor.titulo }}</option>
                        </select>
                        <label for="veiculo-modelo" class="label-input placeholder">Cor <span>*</span></label>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 mt-3">
                    <div class="input-container">
                        <input 
                            v-model="proposta.ano_fabricacao"
                            v-mask="'####'"
                            id="ano_fabricacao" 
                            name="ano_fabricacao" 
                            type="text" 
                            class="input"
                            />
                        <label for="ano_fabricacao" class="label-input placeholder">Ano fabricação <span>*</span></label>
                    </div>
                </div>
            </div>


            <!-- Detalhes -->
            <div v-if="resultadoBusca == true && this.tipo == DefaultEnum.PLACA && modelo_sugerido != null">
                <div class="text-left">
                    <div class="row">
                        <div class="col fipe mt-4" style="">
                            <div class="row">
                                <div class="col-3 text-left">
                                    <label class="font-weight-bold" for="modelo">Modelo:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <span id="modelo" class="">
                                        {{ modelo_sugerido.texto_modelo == null ? veiculo.modelo : modelo_sugerido.texto_modelo }}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col fipe" style="">
                            <div class="row">
                                <div class="col-3 text-left">
                                    <label class="font-weight-bold" for="marca">Marca:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <span id="marca" class="">{{ modelo_sugerido.texto_marca == null ? veiculo.marca : modelo_sugerido.texto_marca }}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col fipe" style="">
                            <div class="row">
                                <div class="col-3 text-left">
                                    <label class="font-weight-bold" for="ano">Ano:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <span id="ano" class="">{{ modelo_sugerido.ano_modelo == null ? veiculo.ano : modelo_sugerido.ano_modelo }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col fipe" style="">
                            <div class="row">
                                <div class="col-3 text-left">
                                    <label class="font-weight-bold" for="codigo_fipe">FIPE:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <span id="codigo_fipe" class="">
                                        {{ modelo_sugerido.codigo_fipe == null ? veiculo.codigo_fipe : modelo_sugerido.codigo_fipe }}
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col fipe" style="">
                            <div class="row">
                                <div class="col-3 text-left">
                                    <label class="font-weight-bold" for="valor">Valor:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <span id="valor" class="">
                                        {{ modelo_sugerido.texto_valor == null ? veiculo.valor : modelo_sugerido.texto_valor }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col fipe" style="">
                            <div class="row">
                                <div class="col-3 text-left">
                                    <label class="font-weight-bold" for="valor">Combustível:&nbsp;</label>
                                </div>
                                <div class="col-9">
                                    <span id="valor" class="">
                                        {{ modelo_sugerido.combustivel == null ? veiculo.combustivel : modelo_sugerido.combustivel }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  -->



                    <div class="row mt-5" v-show="isActive">
                        <div class="col col-12 col-md-6 mb-3" v-for="(modelo, key) in modelos" :key="key">
                            <div class="listacard-opcao" @click="selecionarVeiculo(modelo)">
                                <div class="listacard-info box-container">

                                    <div class="d-flex justify-content-start w-100">
                                        <div class="">
                                            <img class="veiculo-imagem" v-bind:src="veiculo_imagem" v-if="veiculo_imagem">
                                        </div>

                                        <div class="ml-3 w-100">
                                            <div class="detalhes-veiculo">
                                                <span class="marca"> {{ modelo.texto_marca }}</span>&nbsp;
                                                <span class="modelo">{{ modelo.texto_modelo }}</span>&nbsp;&#45;
                                                <span class="ano">{{ modelo.ano_modelo }}</span>&nbsp;&#45;
                                                <span>{{ modelo.combustivel }}</span>&nbsp;<br>
                                                <span>{{ modelo.texto_valor }}</span>&nbsp;
                                            </div>
                                        </div>

                                        <div class="accent-style">
                                            <label class="veiculo-btn-label">
                                                <input type="radio" name="modelo"
                                                    :value="modelo"
                                                    v-model="modelo_sugerido" />
                                            </label>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  -->

                </div>
                <!-- Text left -->

                <div v-if="modelos.length > 0 && tipo == DefaultEnum.PLACA">
                    <p class="mt-3" v-if="!isActive">
                        <i class="fa-solid fa-circle-exclamation text-warning"></i>
                        Há outros {{ modelos.length }} modelos. 
                    </p>

                    <button class="modelos-button button" :class="{ active: isActive }"
                        @click="isActive = !isActive">
                        <span v-if="!isActive">Visualizar modelos</span>
                        <span v-if="isActive">Ocultar modelos</span>               
                    </button>
                </div>

            </div>
            <!--  -->


            <!-- MANUAL -->
            <div class="row" v-if="tipo == DefaultEnum.MANUAL">
                <div class="col-12">
                    <small class="col-12 text-danger" v-if="placa_aviso != null">{{ placa_aviso }}</small>
                    <p>Selecione informações do seu veículo</p>
                </div>
                <div class="col-sm-12 col-md-3 mt-3">
                    <div class="input-container">
                        <select v-model="proposta.veiculo_uso" name="veiculo-uso" class="input">
                            <option disabled selected value="">Selecione o uso veículo</option>
                            <option v-for="(uso, p) in veiculos_uso" :key="p" :value="uso">{{uso.titulo}}</option>
                        </select>
                        <label for="veiculo-uso" class="label-input placeholder">Uso <span>*</span></label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 mt-3">
                    <div class="input-container">
                        <select v-model="proposta.veiculo_tipo" name="veiculo-tipo" class="input">
                            <option disabled value="">Selecione o tipo de veículo</option>
                            <option v-for="(tipo, p) in veiculos_tipo" :key="p" :value="tipo">{{
                                    tipo.titulo
                            }}</option>
                        </select>
                        <label for="veiculo-tipo" class="label-input placeholder">Tipo <span>*</span></label>
                    </div>
                </div>

                <div class="col-sm-12 col-md-3 mt-3">
                    <div class="input-container">
                        <select 
                            v-model="proposta.marca" 
                            name="veiculo-marca" 
                            class="input select" 
                            :disabled="!veiculos_marca.length"
                        >
                            <option disabled value="">
                                {{ !veiculos_marca.length ? "Carregando marcas..." : "Selecione a marca do veículo" }}
                            </option>
                            <option v-for="(marca, idx) in veiculos_marca" :key="idx" :value="marca">
                                {{ marca.titulo }}
                            </option>
                        </select>
                        <label for="veiculo-marca" class="label-input placeholder">Marca <span>*</span></label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 mt-3">
                    <div class="input-container">
                        <select 
                        v-model="proposta.ano" 
                        name="veiculo-ano" 
                        class="input"
                        :disabled="!veiculos_ano.length">
                            <option disabled value="">
                                {{ !veiculos_ano.length ? "Carregando..." : "Selecione o ano do veículo" }}
                            </option>
                            <option v-for="(ano, idx) in veiculos_ano" :key="idx" :value="ano">{{
                                    ano.titulo
                            }}</option>
                        </select>
                        <label for="veiculo-ano" class="label-input label-input placeholder">Ano <span>*</span></label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 mt-3">
                    <div class="input-container">
                        <select v-model="proposta.ano_fabricacao" class="input" id="ano_fabricacao" name="ano_fabricacao">                            
                            <option v-for="ano in anos" :key="ano" :value="ano">{{ ano }}</option>
                        </select>
                        <label for="ano_fabricacao" class="label-input placeholder">Ano fabricação <span>*</span></label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 mt-3">
                    <div class="input-container">
                        <select 
                        v-model="proposta.cor" 
                        name="veiculo-modelo" 
                        class="input"
                        :disabled="!veiculo_cores.length">
                            <option disabled value="">
                                {{ !veiculo_cores.length ? "Carregando cores..." : "Selecione a cor do veículo" }}
                            </option>
                            <option v-for="(cor, idx) in veiculo_cores" :key="idx" :value="cor">
                                {{ cor.titulo }}</option>
                        </select>
                        <label for="veiculo-modelo" class="label-input placeholder">Cor <span>*</span></label>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 mt-3">
                    <div class="input-container">
                        <select 
                        v-model="proposta.modelo" 
                        name="veiculo-modelo" 
                        class="input"
                        :disabled="!veiculos_modelo.length">
                            <option disabled value="">
                                {{ !veiculos_modelo.length ? "Carregando modelos..." : "Selecione o modelo do veículo" }}
                            </option>
                            <option v-for="(modelo, idx) in veiculos_modelo" :key="idx" :value="modelo">
                                {{ modelo.titulo }}</option>
                        </select>
                        <label for="veiculo-modelo" class="label-input placeholder">Modelo <span>*</span></label>
                    </div>
                </div>
            </div>

        </div>

        <div class="d-flex justify-content-between w-100 mt-4 px-3">
            <!--  -->
            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="back()" type="button" name="prev" class="prev left">
                <i class="fa-solid fa-arrow-left-long fa-1x"></i>
            </button>

            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="gerarCotacao()" v-show="propostaLiberada" class="next right" value="Próximo">
                <i class="fa-solid fa-arrow-right-long fa-1x"></i>
            </button>
            <!--  -->
        </div>

    </div>

</template>

<script>
import DefaultEnum from '@/enums/DefaultEnum';

/* eslint-disable */

export default {
    components: {
	},
    
    watch: {
        'tipo': function(){
            this.proposta.veiculo_uso = null;
        },
        'placa': function () {
            if (this.placa.length > 7) {
                this.buscaPlacaVeiculo();
            }
        },
        'proposta.veiculo_tipo': function () {
            this.proposta.marca = {};
            this.buscaMarcaVeiculo();
        },
        // busca marca ao selecionar tipo veiculo
        'proposta.marca': function () {
            this.proposta.ano = {};
            this.buscaAnoVeiculo()
        },
        // busca ano ao selecionar marca veiculo
        'proposta.ano': function () {
            this.proposta.modelo = {};
            this.buscaModeloVeiculo()
        },
        // busca modelo ao selecionar marca veiculo
        'proposta.modelo': function () {
            // this.buscaModeloVeiculo()
        },
    },

    data() {
        return {
            cliente: {},
            veiculos_uso: [],
            veiculos_tipo: [],
            veiculos_marca: [],
            veiculos_modelo: [],
            veiculos_ano: [],
            veiculo_cores: [],
            veiculos: [],
            anos: [],
            tipo: DefaultEnum.PLACA,
            placa: '',
            placa_aviso: null,
            pesquisando_placa: false,
            isActive: false,
            modelo_sugerido: {},
            resultadoBusca: false,
            veiculo: {},
            veiculo_imagem: null,
            modelos: {},
            proposta: {
                veiculo_tipo: {},
                marca: {},
                modelo: {},
                ano: {},
                ano_fabricacao: null,
                veiculo_uso: null,
                combustivel: null,
                cor: null
            },
            cotacao: {
                veiculo: {}
            },
            gerou_proposta: false,
        }
    },

    methods: {
        back(){
            this.clicked();
            this.setCurrentComp('cotacao')
            this.$router.push({ path: `/cotacao`});
        },
        selecionarVeiculo(modelo){
            this.modelo_sugerido = modelo;
        },
        generateYears() {
            const anoAtual = new Date().getFullYear();
            for (let ano = 1980; ano <= anoAtual; ano++) {
                this.anos.push(ano);
            }
        },
        async buscaCores(){
            await this.$http.get('/v1/veiculo-cotacao/veiculo/cores')
                .then((response) => {
                    this.veiculo_cores = response.data.data;

                }).catch((error) => {
                });
        },

        async buscaPlacaVeiculo() {
            this.pesquisando_placa = true;
            this.veiculo = {};
            this.modelos = {};
            this.modelo_sugerido = {};
            this.isActive = false;

            await this.$http.get('/v1/veiculo/placa/' + this.placa)
                .then((response) => {

                    this.resultadoBusca     = true;
                    this.veiculo            = response.data.data;
                    this.veiculo_imagem     = response.data.data.logo;
                    this.modelos            = response.data.data.modelos;
                    this.modelo_sugerido    = this.modelos.filter((modelo) => modelo.codigo_fipe == this.veiculo.codigo_fipe)[0] || {};
                    this.isActive           = this.modelo_sugerido.ano_modelo == null;

                }).catch((error) => {

                    if (error.response.status == 401) {
                        this.tipo = DefaultEnum.MANUAL;
                        this.placa_aviso = 'Sistema de placa temporariamente indisponível.'
                    }
                    
                }).finally(() => this.pesquisando_placa = false);
        },

        async buscaUsosVeiculo() {
            await this.$http.get('/v1/veiculo/uso')
                .then((response) => {
                    this.veiculos_uso = response.data.data;
                }).catch((err) => {

                });
        },
        // busca os tipos de veiculo disponiveis.
        async buscaTiposVeiculo() {
            await this.$http.get('/v1/veiculo/tipo')
                .then((response) => {
                    this.veiculos_tipo = response.data.data;
                }).catch((err) => {

                });
        },
        // busca marcas do veiculo selecionado
        async buscaMarcaVeiculo() {
            this.loadingVeiculosMarca = true;

            const config = { params: { tipo_veiculo: this.proposta.veiculo_tipo.id } };
            if (config.params.tipo_veiculo == null) {
                this.veiculos_marca = {}
                return;
            }

            await this.$http.get(`/v1/veiculo/marca`, config)
                .then((response) => {
                    this.veiculos_marca = response.data.data
                });
        },
        // busca anos do veiculo selecionado
        async buscaAnoVeiculo() {

            const config = {
                params: {
                    tipo_veiculo: this.proposta.veiculo_tipo.id,
                    marca: this.proposta.marca.id
                }
            };

            if (config.params.tipo_veiculo == null || config.params.marca == null) {
                this.veiculos_ano = {};
                return;
            }
            await this.$http.get(`/v1/veiculo/ano`, config)
                .then((response) => {
                    this.veiculos_ano = response.data.data
                }).catch((err) => {
                    console.log(err)
                });
        },
        // busca modelos do veiculo selecionado
        async buscaModeloVeiculo() {
            const config = {
                params: {
                    tipo_veiculo: this.proposta.veiculo_tipo.id,
                    marca: this.proposta.marca.id,
                    ano: this.proposta.ano.id
                }
            };

            if (config.params.tipo_veiculo == null ||
                config.params.marca == null ||
                config.params.ano == null) {
                this.veiculos_modelo = {};
                return;
            }

            await this.$http.get(`/v1/veiculo/modelo`, config)
                .then((response) => {
                    this.veiculos_modelo = response.data.data
                }).catch((err) => {
                    console.log(err)
                });
        },

        //Gerar a cotação para o cliente
        async gerarCotacao() {
            if(!this.proposta.veiculo_uso || !this.proposta.ano_fabricacao || !this.proposta.cor){
                this.$swal.fire('Opss!', 'Você precisa selecionar os obrigatórios para prosseguir.', 'warning');
                return
            }
            this.clicked();
            this.iniLoading();

            this.gerou_proposta = true;
            await this.$http.post('/v1/veiculo-cotacao/cotacao', this.propostaVeiculo)
            .then((response) => {

                this.$secureLS.set('ls_quotation', response.data.data);
                this.setCurrentComp('planos');
                this.$router.push({ path: `/planos/${response.data.data.id}`});

            }).catch((err) => {
                this.isClicked = false;
                this.validarRetorno('Não foi possível atualizar a sua proposta.', err.message)
                this.stopLoading();
            })
        },
    },


    computed: {

        propostaLiberada() {

            if (this.veiculo.cotacao_placa_id != null &&
                this.modelo_sugerido.codigo_fipe != null) {
                return true;
            }
            if (
                this.proposta.veiculo_tipo.id == null
                || this.proposta.marca.id == null
                || this.proposta.ano.id == null
                || this.proposta.modelo.id == null
            ) {
                return false;
            }
            return true;
        },

        propostaVeiculo() {
            return {
                "cliente_id": this.globClienteId,
                "cotacao_placa_id": this.veiculo.cotacao_placa_id,
                'modelo_placa_codigo_fipe': this.modelo_sugerido.codigo_fipe ?? this.veiculo.codigo_fipe,
                'tipo': this.proposta.veiculo_tipo.id,
                'marca_id': this.proposta.marca.id,
                'ano': this.proposta.ano.id,
                'ano_fabricacao': this.proposta.ano_fabricacao,
                'cor': this.proposta.cor.titulo,
                'modelo_id': this.proposta.modelo.id,
                "uso_veiculo": this.proposta.veiculo_uso.id,
                "combustivel": this.modelo_sugerido.combustivel ?? null
            }
        },
    },

    created(){
        this.setCurrentComp('veiculo');
        this.generateYears()
    },
    async mounted() {
        if(this.globClienteId && this.getIdUrl() && this.getIdUrl() != this.globClienteId){
            await this.verificacao('cliente')
        }

        if(!this.globClienteId){
            this.iniLoading()
            await this.verificaClienteComp()
            window.location.reload()
        }

        await this.verificaClienteComp();
        await this.buscaTiposVeiculo()
        await this.buscaUsosVeiculo()
        await this.buscaCores()
        this.iniLoading()
    }
}
</script>

<style scoped>
.input-container {
    margin-top: 10px;
}
.modelos-button {
    margin-top: 0 !important;
}

.detalhes-veiculo {
    position: relative;
    font-weight: lighter;
    text-align: left;
}
.veiculo-imagem {
    width: 45px;
    height: 45px;
    margin-top: 7px;
    margin-right: 5px;
}

.veiculo-btn-label {
    position: relative;
    top: 25px;
    right: -20px;
}

select:disabled {
    background-color: #e1e1e1;
}


</style>