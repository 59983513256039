import { render, staticRenderFns } from "./PagamentoComp.vue?vue&type=template&id=8d5a56b8&scoped=true&"
import script from "./PagamentoComp.vue?vue&type=script&lang=js&"
export * from "./PagamentoComp.vue?vue&type=script&lang=js&"
import style0 from "./PagamentoComp.vue?vue&type=style&index=0&id=8d5a56b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d5a56b8",
  null
  
)

export default component.exports