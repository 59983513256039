<template>
    <div id="header">
        <div class="container-fluid py-3">
            <div class="row d-flex align-items-center">
                <div class="col-sm-12 col-md-8">
                    <a href="#">
                        <img id="logo-cliente" class="img-responsive" :src="logo" alt="logo cliente" style="width: auto; height: 65px;">
                    </a>
                </div>
                <div class="col-sm-12 col-md-4">
                    <span class="d-flex justify-content-center justify-content-lg-end align-items-center mr-xl-4 pr-xl-4">
                        <a :href="this.whatsapp" class="whatsapp">
                            <img src="/img/whatsapp_modern.png" alt="whatsapp">Fale com um consultor
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.whatsapp {
    color: #32ba46 !important;
    text-decoration: none !important;   
}

.whatsapp img {
    width: 65px !important;
    height: 65px !important;
}
</style>

<script>
export default {
    data() {
        return {
            titulo: process.env.VUE_APP_TITULO,
            logo: process.env.VUE_APP_LOGO,
        }
    },
    
}
</script>