<template>
    <div class="row justify-content-center">
        <div class="col-12 mt-2">
            <div v-if="statusContrato == 'aguardando'">
                <h5 class="font-weight-bold">Contrato</h5>
                <p class="text-center mt-3"></p>
                <a v-show="linkContrato || linkContratoLS" :href="linkContrato || linkContratoLS" class="link-contrato button" target="_blank">Clique aqui para assinar seu contrato</a>

            </div>

            <div id="verificando-contrato" v-if="loading || statusContrato == 'verificando'">
                <h5 class="font-weight-bold mb-3">Gerando seu contrato...</h5>
                <!-- Loading -->
                <LoadingDivComp />
                <!-- Loading -->
            </div>

            <div id="erro-contrato" v-if="statusContrato == 'erro'">
                <h5 class="font-weight-bold">Ocorreu um erro inesperado na geração do seu contrato</h5>
                <h6 class="font-weight-bold">Entre em contato com nosso suporte</h6>
                <img class="" src="@/assets/svg/ops.svg" alt="" width="80px" height="250px"><br><br>
            </div>

            <div id="sucesso-contrato" v-if="statusContrato == 'sucesso' && assinado_cliente == false">
                <h5 class="font-weight-bold">Seu contrato foi gerado com sucesso!</h5>
                <img class="" src="@/assets/svg/check.svg" alt="" width="80px" height="350px"><br><br>
                <a class="button link-contrato button"  v-if="linkContrato || linkContratoLS" :href="linkContrato || linkContratoLS">Clique aqui para assinar seu contrato</a>
            </div>

            <div id="sucesso-contrato" class="d-flex justify-content-center align-items-center w-100" v-if="assinado_cliente == true">
                <div class="text-contrato text-left">
                    <p class="font-weight-bold text-center">Olá, {{ this.cliente_nome }},</p>

                    <p class="font-weight-semibold">Obrigado por nos escolher para proteger o seu veículo. Recebemos sua vistoria e o contrato assinado. Nossos analistas irão validar as fotos do seu veículo e entraremos em contato para confirmação.</p>

                    <p class="font-weight-semibold">Qualquer dúvida entre em contato com o número {{whatsapp}}.</p>
                    

                    <br>
                    <img class="" src="@/assets/svg/check.svg" alt="" width="80px" height="350px"><br><br>
                </div>
            </div>
        </div>

        <!--  -->
        <div class="d-flex justify-content-between w-100 mt-3 px-3">
            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="back()" type="button" name="prev" class="prev left">
                <i class="fa-solid fa-arrow-left-long fa-1x"></i> 
            </button>
        </div>
        <!--  -->
    </div>

</template>

<script>
import LoadingDivComp from "@/components/parts/LoadingDivComp.vue";

export default {
    components: {
        LoadingDivComp,
    },

    watch: {
        statusContrato(novoValor){
            if(novoValor == "erro" || novoValor == "sucesso"){
                clearInterval(this.intervalContrato);
            }
        }
    },

	data() {
		return {
            statusContrato: "verificando",
            linkContrato: null,
            linkContratoLS: this.$secureLS.get('ls_url_contrato'),
            whatsapp: process.env.VUE_APP_NUMERO_WHATSAPP,
            assinado_cliente: false,
            cliente_nome: null,
		}
	},

	methods: {
        back() {
            this.clicked();
            this.setCurrentComp('vistoria')
            localStorage.setItem('firstAccess', 'false');
            this.$router.push({ path: `/vistoria/${this.globCotacaoId}`});
        },

        async getContrato(){
            try {

                const response = await this.$http.get('/v1/veiculo-cotacao/cotacao/' + this.globCotacaoId + "/contrato", {
                    headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    },
                });

                if(response.data){
                    return response.data;
                }

            } catch (e) {
                clearInterval(this.intervalContrato);
                this.statusContrato = 'erro';
            }
        },

        async verificaContrato(){
            this.statusContrato = "verificando";
            try {

                var intervalContrato = setInterval(async() => {
                    const contrato = await this.getContrato();
                    const assinado_cliente = contrato.data.cotacao_assinado_cliente ?? null;
                    const linkContrato = contrato.data.autentique_link_cliente ?? null;
                    
                    if(contrato && contrato.data.codigo_acesso != null){
                        if(linkContrato != null){
                            this.linkContrato = linkContrato;
                            this.$secureLS.set('ls_url_contrato', linkContrato);
                            this.statusContrato = "sucesso";
                            this.stopLoading()
                        } else {
                            this.statusContrato = "erro";
                            this.stopLoading()
                        }

                        if(assinado_cliente == true){
                            this.stopLoading()
                            this.assinado_cliente = true;
                            clearInterval(intervalContrato);
                        }
                    }

                }, 30000 );

            } catch(e){
                clearInterval(this.intervalContrato);
                this.statusContrato = "erro";
                this.stopLoading()
            }
        },

	},

    async mounted() {
        if(this.globCotacaoId && this.getIdUrl() && this.getIdUrl() != this.globCotacaoId){
            await this.verificacao('cotacao')
        } 

        if(!this.globCotacaoId){
            await this.verificaCotacaoComp()
            window.location.reload()
        }

        await this.verificaContrato();
        await this.verificaCotacao().then(data => {
            this.cliente_nome = data.veiculo.cliente.nome_completo ?? null;

            if (data.cotacao_pago != 1) {
                this.setCurrentComp('pagamento')
                localStorage.setItem('firstAccess', 'false');
                this.$router.push({ path: `/pagamento/${this.globCotacaoId}`});
            }

            this.stopLoading()
        });
        
        this.setCurrentComp('contrato')
        this.stopLoading()
    },

}
</script>

<style scoped>
.link-contrato {
    cursor: pointer;
    color: #FFF;
}
.link-contrato:hover{
    text-decoration: none;
}
.text-contrato {
    width: 100%;
}

@media only screen and (min-width: 600px) { 
    .text-contrato {
        width: 80%;
    } 
}

@media only screen and (min-width: 768px) { 
    .text-contrato {
        width: 50%;
    }
}

</style>