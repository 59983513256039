<template>
    <div class="row">
        <div class="col-12 w-100">
            <!-- Stepper -->
            <StepperComp />
            <!-- / Stepper -->

            <div class="text-center">
                <h5 class="text-dark font-weight-bold text-center">Você selecionou o plano:</h5>
            </div>

            <div class="plan text-left mt-5 mb-4">
                <h6 class="subtitle exibir-plano">. {{ this.plano.nome }}</h6>
            </div>

            <div class="optional-services text-left mt-3">

                <!-- Benefícios obrigatórios -->
                <h6 class="mt-1 mb-2 font-weight-bold" v-if="this.plano.servicos.filter(v => v.opcional) > 0">Benefícios obrigatórios:</h6>
                <div class="checkbox-wrapper" v-for="(obrigatorio) in this.plano.servicos.filter(v => v.opcional)" :key="obrigatorio.id">
                    <div class="d-flex justify-content-start w-100">
                        <div id="tgl-btn-div beneficio-obrigatorio">
                            <input :id="obrigatorio.id" class="tgl tgl-ios beneficio-obrigatorio" type="checkbox" checked="checked" disabled
                                @change="validarOpcionais(obrigatorio)"
                                :value="obrigatorio
                            ">
                            <label class="tgl-btn beneficio-obrigatorio" :for="obrigatorio.id"></label>
                        </div>
                        <div class="w-100">
                            <p class="ml-2 text-secondary text-left">{{ obrigatorio.titulo }}</p>
                        </div>
                        <div class="">
                            <small class="ml-2">{{ obrigatorio.valor | toCurrency }}</small>
                        </div>
                    </div>
                </div>


                <!-- Benefícios opcionais -->
                <h6 class="mt-1 mb-2 font-weight-bold">Benefícios opcionais:</h6>
                <div class="checkbox-wrapper">
                    <input id="rastreador" class="tgl tgl-ios" type="checkbox" :disabled="rastreador_obrigatorio" v-model="rastreador">
                    <label class="tgl-btn" for="rastreador"></label>
                    <span class="ml-2 text-secondary">Rastreador</span>
                </div>

                <div class="checkbox-wrapper">
                    <input id="aplicativo" class="tgl tgl-ios" type="checkbox" v-model="aplicativo">
                    <label class="tgl-btn" for="aplicativo"></label>
                    <span class="ml-2 text-secondary">Aplicativo</span>
                </div>

                <div class="checkbox-wrapper" v-for="(opcional) in this.plano.servicos.filter(v => !v.opcional) || []" :key="opcional.id">
                    <div class="d-flex justify-content-start w-100">
                        <div id="tgl-btn-div">
                            <input :id="opcional.id" class="tgl tgl-ios" type="checkbox" 
                                @change="validarOpcionais(opcional)"
                                v-model="opcionais" 
                                :value="opcional
                            ">
                            <label class="tgl-btn" :for="opcional.id"></label>
                        </div>
                        <div class="w-100">
                            <p class="ml-2 text-secondary text-left">{{ opcional.titulo }}</p>
                        </div>
                        <div class="">
                            <small class="ml-2">{{ opcional.valor | toCurrency }}</small>
                        </div>
                    </div>
                </div>
            </div>


            <div class="d-flex justify-content-center mt-3 mb-3">
                <div class="box-container w-sm-100 w-md-50 w-lg-50"> 
                    <div class="text-center">
                        <p>Valor de entrada</p>
                        <span class="valor-simbolo">R$</span>
                        <span class="valor-plano" v-if="adesao_total">{{ adesao_total | toCurrency(false) }}</span>
                        <h4 v-else>0,00</h4>
                    </div>
                    <hr />
                    <div class="mb-2">
                        <p class="text-center">Valor total da sua proteção</p>
                        <span class="valor-simbolo">R$</span>
                        <span class="valor-plano">{{ mensalidade_total | toCurrency(false) }} / mês</span>
                        <hr>
                        <div class="text-center">
                            <small class=""><b>Mensalidade:</b> {{ this.plano.mensalidade | toCurrency }}</small><br>
                            <small class=""><b>Rastreador:</b> {{ mensalidade_rastreador | toCurrency }}</small><br>
                            <small class=""><b>Serviço total:</b> {{ mensalidade_servico_total | toCurrency }}</small><br>
                        </div>
                    </div>
                    <hr>
                    <small>Primeira mensalidade em aproximadamente 30 dias após a adesão</small>
                </div>
            </div>
            
        </div>


        <div class="d-flex justify-content-between w-100 mt-3 px-3">
            <!--  -->
            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="back()" type="button" name="prev" class="prev left">
                <i class="fa-solid fa-arrow-left-long fa-1x"></i> 
            </button>

            <button :disabled="this.isClicked" :class="{ 'clicked': isClicked }" @click="atualizarCotacao()" type="button" name="next" class="next right">
                <i class="fa-solid fa-arrow-right-long fa-1x"></i>
            </button>
            <!--  -->
        </div>
    </div>
</template>

<script>

/* eslint-disable */
export default {
    components: {
    },
    
    props: {
    },

    watch: {
        "aplicativo": function () {
            this.rastreador_obrigatorio = false;
            if (this.aplicativo && this.plano.rastreador_obrigatorio_app) {
                this.rastreador = true;
                this.rastreador_obrigatorio = true;
            }
            if (!this.aplicativo) {
                this.rastreador = false;
                this.rastreador_obrigatorio = false;
            }
        },
        "this.plano": function () {
            this.opcionais = [];
            this.rastreador = this.plano.rastreador;
            this.rastreador_obrigatorio = this.plano.rastreador;
        },
    },

    data() {
        return {
            plano: this.$secureLS.get('ls_choosed_plan'),
            opcionais: [],
            rastreador: false,
            aplicativo: false,
            rastreador_obrigatorio: false,
            disponibilidade: null,
        };
    },
    methods: {
        back() {
            this.clicked();
            this.setCurrentComp('planos');
            this.$router.push({ path: `/planos/${this.globCotacaoId}`});
        },

        logge() {
            this.disponibilidade = true;
        },
        
        validarOpcionais(servico) {
            this.opcionais = this.opcionais.filter((s) => s.classe == "" || s == servico || servico.classe != s.classe);
        },

        async atualizarCotacao() {
            this.clicked();

            let opcionais = [];
            this.opcionais.forEach(o => opcionais.push(o.id));

            var qs = require("qs");
            await this.$http.put("/v1/veiculo-cotacao/cotacao/" + this.globCotacaoId, qs.stringify({
                rastreador: this.rastreador,
                aplicativo: this.aplicativo,
                plano_valor_id: this.plano.plano_valor_id,
                plano_servicos: opcionais,
            })).then((response) => {
                this.$secureLS.set('ls_quotation', response.data.data);

                // Next
                this.setCurrentComp('cliente');
                localStorage.setItem('firstAccess', 'false');
                this.$router.push({ path: `/cliente/${this.globCotacaoId}`});

            }).catch((err) => {
                this.isClicked = false;
                this.setCurrentComp('beneficios');
                localStorage.setItem('firstAccess', 'false');
                this.validarRetorno("Não foi possível criar a sua proposta", err.response.data);
            })
        },

        // getSymbolClass(service, item, index) {
        //     const symbol = this.incluso(service, item, index);
        //     if (symbol === '✔') {
        //         return 'text-success';
        //     } 
            
        //     if (symbol === '✘') {
        //         return 'text-danger';
        //     } 
            
        //     if (symbol === '＄') {
        //         return 'text-primary';
        //     }
        //     return ''; // Caso nenhum símbolo corresponda, não aplica nenhuma classe
        // },

        // incluso(service, item, index) {
        //     const propost = item.servicos;
        //     for(let i = 0; i < propost.length; i++) {
        //         if (propost[i].titulo == service && propost[i].valor == 0) {
        //             return "✔";
        //         } else if (propost[i].titulo == service && propost[i].valor != 0) {
        //             return "＄";
        //         }
        //     } return "✘";
        // },

        // uniqueServicesFunction(value) {
        //     const sevic = [];
        //     const temptittle = []
        //     for (let i = 0; i < value.length; i++) {
        //         const el = value[i].servicos;
        //         sevic.push(el);
        //     }

        //     for(let i = 0; i < sevic.length; i++) {
        //         for(let j = 0; j < sevic[i].length; j++) {
        //             temptittle.push(sevic[i][j]);
        //         }
        //     }
            
        //     for (let i = 0; i < temptittle.length; i++) {
        //         const element = temptittle[i].titulo;
        //         if (this.uniqueServices.length == 0) {
        //             this.uniqueServices.push(element);
        //         }
        //         if (!this.uniqueServices.includes(element)) {
        //             this.uniqueServices.push(element);
        //         }
        //     }
        //     return this.uniqueServices;
        // },

    },
    computed: {
        mensalidade_servico_total() {
            var total = 0;
            this.opcionais.forEach(v => {
                total += v.valor;
            });
            return total;
        },
        mensalidade_obrigatorios(){
            let total = 0;
            this.plano.servicos.forEach(o => {
                if(o.opcional){
                    total += o.valor;
                }
            })

            return total;
        },
        mensalidade_total() {
            var total = this.plano.mensalidade;
            total += this.mensalidade_rastreador;
            total += this.mensalidade_servico_total;
            total += this.mensalidade_obrigatorios;
            return total ? total : 0;
        },
        adesao_total() {
            return this.plano.adesao + this.adesao_rastreador;
        },
        adesao_rastreador() {
            if (this.rastreador) {
                return this.plano.rastreador_adesao;
            }
            return 0;
        },
        mensalidade_rastreador() {
            if (this.rastreador) {
                return this.plano.rastreador_mensalidade;
            }
            return 0;
        },
        propostaLiberada() {
            if (this.proposta.veiculo_tipo.id == null
                || this.proposta.marca.id == null
                || this.proposta.ano.id == null
                || this.proposta.modelo.id == null) {
                return false;
            }
            return true;
        },
        propostaVeiculo() {
            return {
                "cliente_id": this.cliente_id,
                "tipo_veiculo": this.proposta.veiculo_tipo.id,
                "marca_id": this.proposta.marca.id,
                "ano": this.proposta.ano.id,
                "modelo_id": this.proposta.modelo.id,
            };
        },
    },

    created(){
        this.setCurrentComp('beneficios')
    },
    async mounted() {
        if(this.globCotacaoId && this.getIdUrl() && this.getIdUrl() != this.globCotacaoId){
            await this.verificacao('cotacao')
        } 

        if(!this.globCotacaoId){
            this.iniLoading()
            await this.verificaCotacaoComp()
            this.setCurrentComp('planos')
        }
    },
}
</script>

<style scoped>

p {
    margin-bottom: 0;
}

#tgl-btn-div {
    max-width: 80px;
}

.beneficio-obrigatorio {
    cursor: no-drop !important;
    opacity: 0.5 !important;
    background: var(--cor-primaria) !important;
}

.box-container {
    border-top: 1px solid var(--cor-primaria);
}
.prev.button {
    background-color: rgb(255 255 255);
}

.input-radio{
    text-align: left;
    margin-left: 0.3em;
}
</style>