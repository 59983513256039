<template>
    <div class="root bg-section">
        <!-- Header -->
        <partHeader />

        <!-- Start -->
        <div class="main-container" v-if="this.getStep.current === 'cotacao' ">
            <cotacao />
        </div>

        <!-- Components -->
        <div class="main-container px-3" v-if="this.getStep.current !== 'cotacao' ">

            <div class="row justify-content-center">
                <div class="col-sm-12 col-md-8 mt-5 mt-5 mb-5">
                    <div class="box-container mt-3">
                        
                        <component :is="this.getStep.current" @component-loaded="onComponentLoaded"/>

                    </div>
                </div>
            </div>

        </div>

        <fab
        :main-icon="mainIcon"
        :icon-size="iconSize"
        :position="position"
        :bg-color="bgColor"
        :actions="fabActions"
        :enable-rotation=false
        @novaCotacao="novaCotacao"
        @openWhatsapp="openWhatsapp"
        ></fab>

        <!-- Footer -->
        <partFooter />
    </div>
</template>
  
<script>
/* eslint-disable */
// Base
import partHeader from "@/components/parts/HeaderComp.vue";
import partFooter from "@/components/parts/FooterComp.vue";
// Auxiliares
import LoadingComp from "@/components/parts/LoadingComp.vue";
import LoadingDivComp from "@/components/parts/LoadingDivComp.vue";
import { mapGetters } from 'vuex';
import mixin from '@/mixin';
// Telas/Componentes
import cotacao from "@/components/cliente/ClienteBase.vue";
import veiculo from '@/components/veiculo/VeiculoComp.vue';
import planos from '@/components/plano/PlanosComp.vue';
import beneficios from '@/components/plano/BeneficiosComp.vue';
import cliente from '@/components/cliente/ClienteCompleto.vue';
import pagamento from "@/components/pagamento/PagamentoComp.vue";
import vistoria from "@/components/vistoria/VistoriaComp.vue";
import contrato from "@/components/contrato/ContratoComp.vue";
import fab from 'vue-fab'

export default {
    components: {
        partHeader,
        partFooter,
        LoadingComp,
        LoadingDivComp,
        fab,
        cotacao,
        veiculo,
        planos,
        beneficios,
        cliente,
        pagamento,
        vistoria,
        contrato
    },
    mixins: mixin,
    data() {
        return {
            mainIcon: "help",
            iconSize: "small",
            position: "bottom-right",
            bgColor: "var(--cor-primaria)",
            fabActions: [
              {
                  name: 'novaCotacao',
                  icon: 'replay',
                  tooltip: 'Nova cotação'
              },
              {
                  name: 'openWhatsapp',
                  icon: 'call',
                  tooltip: 'Fale com um consultor'
              }
          ]
        }
    },
    computed: {
        ...mapGetters({
            getStep: 'getStep',
            
        }),
    },
    methods: {
        novaCotacao(){
            this.$swal({
              title: 'Deseja iniciar uma nova cotacao ?',
              text: 'Todos os dados preenchidos até aqui seram perdidos. Tem certeza ?',
              type: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Permanecer na atual',
              confirmButtonText: 'Começar nova cotação',
              showCloseButton: true,
              showLoaderOnConfirm: true
            }).then((result) => {
              if(result.value) {
                this.$secureLS.set('ls_quotation', null);
                this.$secureLS.set('ls_customer', null);
                this.setCurrentComp('cotacao');
              }
            })

        },
        openWhatsapp(){
            const newTab = window.open(this.whatsapp, '_blank');
            if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
                alert('O bloqueador de pop-ups está ativado. Permita pop-ups para abrir o link.');
            }
        },
        onComponentLoaded() {
          this.isClicked = false;
        },
        checkComp(){
            const url = this.getCompUrl() ?? 'cotacao';
            const storage = this.getStep.current ?? 'cotacao';

            if(url && localStorage.getItem('firstAccess') == 'true'){
                this.setCurrentComp(url);
                this.$secureLS.set('ls_quotation', null);
                this.$secureLS.set('ls_customer', null);
            } else {
                this.setCurrentComp(storage);
            }
        }
    },
    created(){
        if (!localStorage.getItem('firstAccess')) {
            localStorage.setItem('firstAccess', 'true');
        } else {
            localStorage.setItem('firstAccess', 'false');
        }

        this.checkComp()
    },
    mounted(){
    },
    
}
</script>
<style>
.fab-main{
    padding: 23px !important;
}
</style>